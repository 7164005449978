#homeJumboContainer {
    height:75vh;
    padding: 0px;
    margin:0px;
} 

#homeJumbotron {
    background: url(../../assets/clifton-suspension-bridge-2496334_1920.jpg) no-repeat; 
    background-size: cover;
    height:100%;
    border-radius: 0px;
    padding:0px;
}

h1.homepage {
    color: rgb(13, 173, 221);
    padding:0.2em;
    border:5px solid rgb(13, 173, 221);
    border-radius:0.5em;
    background-color:rgba(255,255,255,0.4);
    width: fit-content;
    position:relative;
    left:5vw;
    top: 5vw;
    
}

.BristolHead {
    font-family:'Kaushan Script', sans-serif;font-size: 350%;
}

.SingsHead {
    margin-top:-20px;
    font-size:200%;
    margin-left:20px;
    color: rgb(13, 173, 221);
    font-family: 'Permanent Marker', sans-serif; font-size: 350%;
}


