h2.headline {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 5em;
    text-align:center;
    width:100%;
    padding: 0.5em 0 0.5em 0;
}

/* sizes images to full width - desktop */
img[class*="wp-image-"] {
    width:100%;
    height:100%;
}

.news-story-rightcol {
    padding-top:50px;
}

.news-story-rightcol li {
    list-style:none;

}