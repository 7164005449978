.eventorganiser.card-body {
    padding:0;
}

.eventCardBody {
    margin:6px;
    margin-bottom:25px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px rgb(172, 166, 166);
}


.eventDateBlockWrap {
    width:70px;
    height:100%;

}

.eventDateBlock {
    height:60px;
    border:2px solid black;
    border-radius: 7px;
    
    width:60px;
    float:left;

} 

.eventInfoLine {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color:gray;
    font-weight:bold;
}

.eventTextLine {
    margin-bottom: 0.5em;
    font-weight:300;
}

.eventDateDay {
    text-align: center;
    height: 50%;
    background-color:white;
    color:gray;
    border-bottom-left-radius:7px;
    border-bottom-right-radius:7px;
}

.eventDateMonth {
    text-align: center;
    height: 50%;
    background-color:red;
    color:white;  
    border-top-left-radius:7px;
    border-top-right-radius:7px;
}

.eventTitleDiv {
    display:inline-block;
}

.eventTitle {
    line-height: 0.5em;
    margin:0px;
    margin-top:5px;
    display: inline-block;
}

.eventInfo {
    margin-left: 6em;
}

.eventMoreButton {
    border-radius: 10px;
    color: black;
    background-color:silver
}

.eventorganiser {
    font-size: 75%;
}

.eventLePCol {
    margin-bottom:14px;
}

.eventLePCard {
    margin:7px;
    border: 2px solid silver;
    border-radius: 10px;
    padding:0.5em;
    width: 100%;
    position:relative;
 
}

.eventsubTitle {
    color: gray;
    font-weight: bold;
    margin-top:1em;
    margin-bottom: 1em;
}

.eventmaintextLeft {
    font-family:"Noto Sans", Arial, sans-serif;
    
    width: 50%;
    float:left;
}

.eventmaintextRight {
    width: 50%;
    float: right;
    
}

.eventPoster {
    display: block;
    overflow: hidden;
    position: relative;
    text-align: center;

}

.eventPoster img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.eventMainRow {
    margin-bottom:1em;
}

.eventDivider {
    border-bottom: 1px solid silver;
    width:95%;
    margin:0 auto;
}

.eventInfoRow {
  position: absolute;
  bottom: 0px;
  height: 35px;
  left: 0em;
  padding:0px !important; /* Cancels a bootstrap setting */
}

.eventInfoLine {
    left:2em;
}

.imageFltRt {
    float:right;
}