nav {
    padding-left:6em;
    
}

nav a, nav a:hover {
    color:white
}

nav a.nav-link {
    color:white;
}

nav a.nav-link:hover {
    color:rgb(228, 222, 222);
}