footer {
    background-color: rgba(21, 28, 70, 0.726);
    color: white;
    font-family:Poppins, sans-serif;
    font-weight:300;
    font-size:11px;
}

.footer-wrap {
    padding:1em;
}

.footer-right-col {
    border-left:1px solid white;
}