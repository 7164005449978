.choirCard {
    list-style: none;
    border:1px solid silver; 
    box-shadow: 0px 0px 10px 10px silver;
    border-radius: 1em;
    padding:1.5em;
    margin:2em 0;
}

.titleContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-items: auto;
    align-content: start
  }

  .titleItem {
    flex: 0 0 auto;
    margin: 10px;
  }

  .choirLogoImage {
      max-width:100px;
      max-height:100px;
      float:right;
  }

  h2.choirHeader {
    
    width:100%
  }

  .choirHeaderRight {

    width:50%;
  }
