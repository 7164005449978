.homePanels_wrap {
background-color: rgba(13, 173, 221,0.5);
padding: 1em;
margin-bottom: 1.5em;
}


.lep-card {
    padding:2em;
    margin:1em 0 1em 0;
    font-family: "Poppins", serif;
    font-weight:300;
    color: #333;
}

.icon-card {
    
    padding-top:0;
}

.lep-icon {
    font-size:4em !important;
    color: #aaa !important;
    padding-top:.25em; padding-bottom: .25em;
}

.lep-panel-title {
    font-size: 2em;
    font-family:'Kaushan Script', sans-serif; 
    color: rgba(13, 173, 221,0.5);
    margin-bottom: 1em;
}

