/* .bp-header-container {
    padding: 0px;
    margin:0px;
    height:25vh;
}

.bp-jumbotron {
    background: url(./assets/clifton-suspension-bridge-2496334_1920.jpg) no-repeat; 
    background-size: cover;   
    border-radius: 0px;
    padding:0px;

}

h1 {
    
    color: rgb(13, 173, 221);
    padding:0.2em;
    border:5px solid rgb(13, 173, 221);
    border-radius:0.5em;
    background-color:rgba(255,255,255,0.4);
    width: fit-content;
    position:relative;
    left:5vw;
    top: 5vw;
    
}
*/

h1.backpage {
    color:rgb(13, 173, 221);
    position:relative;
    border-radius: 0.2em;
    width:fit-content;
    top:1vh;
    padding:0.2em;
    border:5px solid rgb(13, 173, 221);
    border-radius:0.5em;
    left:5vh;
}

#bp-header-container {
    
    padding: 0px;
    margin:0px;
}

#bp-jumbotron {
    
    background: url(./assets/clifton-suspension-bridge-2496334_1920.jpg) no-repeat;
    background-position-y: -200px;
    background-size: cover; 
    border-radius:0px;
    padding: 0px;
    margin:0px;
    height:25vh;
}
.bp-BristolHead {
    font-family:'Kaushan Script', sans-serif;font-size: 10vh;color: rgb(13, 173, 221);
}

.bp-SingsHead {
    margin-top:-20px;
    
    margin-left:20px;
    color: rgb(13, 173, 221);
    font-family: 'Permanent Marker', sans-serif; font-size: 10vh;
}
