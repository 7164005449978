@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;1,300&family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Poppins", serif;
  background-color:rgb(226, 239, 240) ;
}

nav {
    padding-left:6em;
    
}

nav a, nav a:hover {
    color:white
}

nav a.nav-link {
    color:white;
}

nav a.nav-link:hover {
    color:rgb(228, 222, 222);
}
#homeJumboContainer {
    height:75vh;
    padding: 0px;
    margin:0px;
} 

#homeJumbotron {
    background: url(/static/media/clifton-suspension-bridge-2496334_1920.fcb586c4.jpg) no-repeat; 
    background-size: cover;
    height:100%;
    border-radius: 0px;
    padding:0px;
}

h1.homepage {
    color: rgb(13, 173, 221);
    padding:0.2em;
    border:5px solid rgb(13, 173, 221);
    border-radius:0.5em;
    background-color:rgba(255,255,255,0.4);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position:relative;
    left:5vw;
    top: 5vw;
    
}

.BristolHead {
    font-family:'Kaushan Script', sans-serif;font-size: 350%;
}

.SingsHead {
    margin-top:-20px;
    font-size:200%;
    margin-left:20px;
    color: rgb(13, 173, 221);
    font-family: 'Permanent Marker', sans-serif; font-size: 350%;
}



.homePanels_wrap {
background-color: rgba(13, 173, 221,0.5);
padding: 1em;
margin-bottom: 1.5em;
}


.lep-card {
    padding:2em;
    margin:1em 0 1em 0;
    font-family: "Poppins", serif;
    font-weight:300;
    color: #333;
}

.icon-card {
    
    padding-top:0;
}

.lep-icon {
    font-size:4em !important;
    color: #aaa !important;
    padding-top:.25em; padding-bottom: .25em;
}

.lep-panel-title {
    font-size: 2em;
    font-family:'Kaushan Script', sans-serif; 
    color: rgba(13, 173, 221,0.5);
    margin-bottom: 1em;
}


.standfirst.text-center{
    margin-top:2em;
    font-family:'Poppins', sans-serif;
    font-size: 35px;;
    font-weight:100;
}
.lep-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    
}

.newspanel {
    height: 30em;
}
h2.headline {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 5em;
    text-align:center;
    width:100%;
    padding: 0.5em 0 0.5em 0;
}

/* sizes images to full width - desktop */
img[class*="wp-image-"] {
    width:100%;
    height:100%;
}

.news-story-rightcol {
    padding-top:50px;
}

.news-story-rightcol li {
    list-style:none;

}
/* .bp-header-container {
    padding: 0px;
    margin:0px;
    height:25vh;
}

.bp-jumbotron {
    background: url(./assets/clifton-suspension-bridge-2496334_1920.jpg) no-repeat; 
    background-size: cover;   
    border-radius: 0px;
    padding:0px;

}

h1 {
    
    color: rgb(13, 173, 221);
    padding:0.2em;
    border:5px solid rgb(13, 173, 221);
    border-radius:0.5em;
    background-color:rgba(255,255,255,0.4);
    width: fit-content;
    position:relative;
    left:5vw;
    top: 5vw;
    
}
*/

h1.backpage {
    color:rgb(13, 173, 221);
    position:relative;
    border-radius: 0.2em;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    top:1vh;
    padding:0.2em;
    border:5px solid rgb(13, 173, 221);
    border-radius:0.5em;
    left:5vh;
}

#bp-header-container {
    
    padding: 0px;
    margin:0px;
}

#bp-jumbotron {
    
    background: url(/static/media/clifton-suspension-bridge-2496334_1920.fcb586c4.jpg) no-repeat;
    background-position-y: -200px;
    background-size: cover; 
    border-radius:0px;
    padding: 0px;
    margin:0px;
    height:25vh;
}
.bp-BristolHead {
    font-family:'Kaushan Script', sans-serif;font-size: 10vh;color: rgb(13, 173, 221);
}

.bp-SingsHead {
    margin-top:-20px;
    
    margin-left:20px;
    color: rgb(13, 173, 221);
    font-family: 'Permanent Marker', sans-serif; font-size: 10vh;
}

.eventorganiser.card-body {
    padding:0;
}

.eventCardBody {
    margin:6px;
    margin-bottom:25px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px rgb(172, 166, 166);
}


.eventDateBlockWrap {
    width:70px;
    height:100%;

}

.eventDateBlock {
    height:60px;
    border:2px solid black;
    border-radius: 7px;
    
    width:60px;
    float:left;

} 

.eventInfoLine {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color:gray;
    font-weight:bold;
}

.eventTextLine {
    margin-bottom: 0.5em;
    font-weight:300;
}

.eventDateDay {
    text-align: center;
    height: 50%;
    background-color:white;
    color:gray;
    border-bottom-left-radius:7px;
    border-bottom-right-radius:7px;
}

.eventDateMonth {
    text-align: center;
    height: 50%;
    background-color:red;
    color:white;  
    border-top-left-radius:7px;
    border-top-right-radius:7px;
}

.eventTitleDiv {
    display:inline-block;
}

.eventTitle {
    line-height: 0.5em;
    margin:0px;
    margin-top:5px;
    display: inline-block;
}

.eventInfo {
    margin-left: 6em;
}

.eventMoreButton {
    border-radius: 10px;
    color: black;
    background-color:silver
}

.eventorganiser {
    font-size: 75%;
}

.eventLePCol {
    margin-bottom:14px;
}

.eventLePCard {
    margin:7px;
    border: 2px solid silver;
    border-radius: 10px;
    padding:0.5em;
    width: 100%;
    position:relative;
 
}

.eventsubTitle {
    color: gray;
    font-weight: bold;
    margin-top:1em;
    margin-bottom: 1em;
}

.eventmaintextLeft {
    font-family:"Noto Sans", Arial, sans-serif;
    
    width: 50%;
    float:left;
}

.eventmaintextRight {
    width: 50%;
    float: right;
    
}

.eventPoster {
    display: block;
    overflow: hidden;
    position: relative;
    text-align: center;

}

.eventPoster img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.eventMainRow {
    margin-bottom:1em;
}

.eventDivider {
    border-bottom: 1px solid silver;
    width:95%;
    margin:0 auto;
}

.eventInfoRow {
  position: absolute;
  bottom: 0px;
  height: 35px;
  left: 0em;
  padding:0px !important; /* Cancels a bootstrap setting */
}

.eventInfoLine {
    left:2em;
}

.imageFltRt {
    float:right;
}
.choirCard {
    list-style: none;
    border:1px solid silver; 
    box-shadow: 0px 0px 10px 10px silver;
    border-radius: 1em;
    padding:1.5em;
    margin:2em 0;
}

.titleContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: auto;
            align-items: auto;
    -webkit-align-content: start;
            align-content: start
  }

  .titleItem {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    margin: 10px;
  }

  .choirLogoImage {
      max-width:100px;
      max-height:100px;
      float:right;
  }

  h2.choirHeader {
    
    width:100%
  }

  .choirHeaderRight {

    width:50%;
  }

footer {
    background-color: rgba(21, 28, 70, 0.726);
    color: white;
    font-family:Poppins, sans-serif;
    font-weight:300;
    font-size:11px;
}

.footer-wrap {
    padding:1em;
}

.footer-right-col {
    border-left:1px solid white;
}
